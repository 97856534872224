import React, { useState } from "react";
import { usePersonalityContext } from "../context/PersonalityContext";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useLottie } from "lottie-react";
import anaconda from "../lottie/ANACONDA.json";
import assassin from "../lottie/ASSASIN.json";
import artist from "../lottie/ARTIST.json";
import caveman from "../lottie/CAVEMAN.json";
import champion from "../lottie/CHAMPION.json";
import grappler from "../lottie/GRAPPLER.json";
import grinder from "../lottie/GRINDER.json";
import inventor from "../lottie/INVENTOR.json";
import jester from "../lottie/JESTER.json";
import machine from "../lottie/MACHINE.json";
import madscientist from "../lottie/MAD SCIENTIST.json";
import magician from "../lottie/MAGICIAN.json";
import mastermind from "../lottie/MASTERMIND.json";
import professor from "../lottie/PROFESSOR.json";
import romantic from "../lottie/ROMANTIC.json";
import swindler from "../lottie/SWINDLER.json";
import technician from "../lottie/TECHNICIAN.json";
import wildcard from "../lottie/WILDCARD.json";
import wizard from "../lottie/WIZARD.json";
import wrestler from "../lottie/WRESTLER.json";
import Personalities from "../components/Personalities";
import Share from "../components/Share";
import sharedData from "../data/results.json";

function ResultPage() {
  const { scores, character } = usePersonalityContext();
  const { characterId } = useParams();
  const [shareVisible, setShareVisible] = useState(false);
  const quizTaken =
    scores.decision1.intuition !== 0 && scores.decision1.calculation !== 0;

  const characterToJSON = {
    anaconda: anaconda,
    artist: artist,
    assassin: assassin,
    caveman: caveman,
    champion: champion,
    grappler: grappler,
    grinder: grinder,
    inventor: inventor,
    jester: jester,
    machine: machine,
    madscientist: madscientist,
    magician: magician,
    mastermind: mastermind,
    professor: professor,
    romantic: romantic,
    swindler: swindler,
    technician: technician,
    wildcard: wildcard,
    wizard: wizard,
    wrestler: wrestler,
  };

  const animationData =
    characterToJSON[
      quizTaken ? character.id.toLowerCase() : characterId.toLocaleLowerCase()
    ];

  const options = {
    animationData: animationData,
    loop: false,
  };

  const lottieStyle = {
    height: 176,
  };
  const { View } = useLottie(options, lottieStyle);

  let indicatorPlace;
  let indicatorPlace2;
  let indicatorPlace3;
  let indicatorPlace4;

  if (quizTaken) {
    indicatorPlace = (scores.decision1.intuition / 5) * 100;
    indicatorPlace2 = (scores.decision2.attacking / 5) * 100;
    indicatorPlace3 = (scores.decision3.calm / 5) * 100;
    indicatorPlace4 = (scores.decision4.playful / 5) * 100;
  } else {
    const defaultScores = sharedData.find(
      (item) => item.id === characterId
    ).defaultScores;
    indicatorPlace = (defaultScores.decision1.intuition / 5) * 100;
    indicatorPlace2 = (defaultScores.decision2.attacking / 5) * 100;
    indicatorPlace3 = (defaultScores.decision3.calm / 5) * 100;
    indicatorPlace4 = (defaultScores.decision4.playful / 5) * 100;
  }

  const showShareButtons = () => {
    setShareVisible(true);
  };

  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>
          {quizTaken
            ? character.name
            : sharedData.find((item) => item.id === characterId).name}{" "}
          - Chess Personality by Chess.com
        </title>
        <meta
          name="description"
          content={
            quizTaken
              ? character.description
              : sharedData.find((item) => item.id === characterId).description
          }
        />
        <meta
          property="og:title"
          content={
            quizTaken
              ? character.name
              : sharedData.find((item) => item.id === characterId).name
          }
        />
        <meta
          property="og:image"
          content={
            quizTaken
              ? character.image
              : sharedData.find((item) => item.id === characterId).image
          }
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <section className="results">
        {quizTaken && (
          <p className="results-title mobile-only">Time for the big reveal</p>
        )}
        <div className="image-wrapper">{View}</div>
        <h2 className="results-heading">
          {quizTaken ? <span>You are The </span> : <span>The </span>}
          {quizTaken
            ? character.name
            : sharedData.find((item) => item.id === characterId).name}
        </h2>
        <div className="description-container">
          <div className="description desktop-only">
            <div className="description-text">
              {quizTaken
                ? character.description
                : sharedData.find((item) => item.id === characterId)
                    .description}
            </div>
            <div className="share-container">
              {quizTaken ? (
                <button
                  onClick={showShareButtons}
                  className="btn-primary share-button-main"
                >
                  <img
                    src="https://images.chesscomfiles.com/uploads/ad_image/19013.756b15eb.png"
                    alt="share"
                    width="32"
                    height="32"
                  />
                  <span>Share Result</span>
                </button>
              ) : (
                <a href="/" className="btn-primary share-button-main">
                  <span>Discover your personality</span>
                </a>
              )}
              <div className={`share-buttons ${shareVisible ? "visible" : ""}`}>
                <Share />
              </div>
            </div>
          </div>
          <div className="description-visual">
            <div className="percents">
              <div className="decision-percents">
                <div className="labels-container">
                  <span className="label">intuition</span>
                  <span className="label">calculation</span>
                </div>
                <div className="percents-bar">
                  <div className="percent-bar-inside">
                    <div
                      className="indicator"
                      style={{ right: `${indicatorPlace}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="decision-percents">
                <div className="labels-container">
                  <span className="label">attacking</span>
                  <span className="label">positional</span>
                </div>
                <div className="percents-bar">
                  <div className="percent-bar-inside">
                    <div
                      className="indicator"
                      style={{ right: `${indicatorPlace2}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="decision-percents">
                <div className="labels-container">
                  <span className="label">calm</span>
                  <span className="label">emotional</span>
                </div>
                <div className="percents-bar">
                  <div className="percent-bar-inside">
                    <div
                      className="indicator"
                      style={{ right: `${indicatorPlace3}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="decision-percents">
                <div className="labels-container">
                  <span className="label left">playful</span>
                  <span className="label right">studious</span>
                </div>
                <div className="percents-bar">
                  <div className="percent-bar-inside">
                    <div
                      className="indicator"
                      style={{ right: `${indicatorPlace4}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="description mobile-only">
              <div className="description-text">
                {quizTaken
                  ? character.description
                  : sharedData.find((item) => item.id === characterId)
                      .description}
              </div>
              <div className="share-container">
                {quizTaken ? (
                  <button
                    onClick={showShareButtons}
                    className="btn-primary share-button-main"
                  >
                    <img
                      src="https://images.chesscomfiles.com/uploads/ad_image/19013.756b15eb.png"
                      alt="share"
                      width="32"
                      height="32"
                    />
                    <span>Share Result</span>
                  </button>
                ) : (
                  <a href="/" className="btn-primary share-button-main">
                    <span>Discover your personality</span>
                  </a>
                )}
                <div className={`share-buttons ${shareVisible ? "block" : ""}`}>
                  <Share />
                </div>
              </div>
            </div>

            <a
              href={
                quizTaken
                  ? character.celibrityLink
                  : sharedData.find((item) => item.id === characterId)
                      .celibrityLink
              }
              className="celibrity-container"
            >
              <img
                src={
                  quizTaken
                    ? character.celibrityImgUrl
                    : sharedData.find((item) => item.id === characterId)
                        .celibrityImgUrl
                }
                alt="celibrity"
                width="67"
                height="67"
              />
              <div className="celibrity">
                <p className="celibrity-heading">Player like you</p>
                <p className="celibrity-description">
                  {quizTaken
                    ? character.celibrityDesc
                    : sharedData.find((item) => item.id === characterId)
                        .celibrityDesc}
                </p>
              </div>
            </a>

            <div className="opening-container">
              <img
                src="https://images.chesscomfiles.com/uploads/ad_image/19123.e3da29f0.png"
                alt="book"
                width="67"
                height="67"
              />
              <div className="opening">
                <p className="opening-heading">Openings for you</p>
                <span className="opening-description">
                  <a
                    href={
                      quizTaken
                        ? character.opening1.url
                        : sharedData.find((item) => item.id === characterId)
                            .opening1.url
                    }
                  >
                    {quizTaken
                      ? character.opening1.title
                      : sharedData.find((item) => item.id === characterId)
                          .opening1.title}
                  </a>
                </span>{" "}
                or{" "}
                <span className="opening-description">
                  <a
                    href={
                      quizTaken
                        ? character.opening2.url
                        : sharedData.find((item) => item.id === characterId)
                            .opening2.url
                    }
                  >
                    {quizTaken
                      ? character.opening2.title
                      : sharedData.find((item) => item.id === characterId)
                          .opening2.title}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Personalities />
    </>
  );
}

export default ResultPage;
